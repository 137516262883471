/*@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');*/

/*@font-face {
  font-family: 'Inconsolata';
  src: local('Inconsolata'), url('./assets/fonts/Inconsolata-Regular.woff') format('woff');
}*/

@font-face {
  font-family:"neue-haas-grotesk-display";
  src:url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:500;
  font-stretch:normal;
}
  
@font-face {
  font-family:"neue-haas-grotesk-display";
  src:url("https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:700;
  font-stretch:normal;
}

@font-face {
  font-family:"adelle-mono";
  src:url("https://use.typekit.net/af/c357dd/00000000000000007735f0f5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/c357dd/00000000000000007735f0f5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/c357dd/00000000000000007735f0f5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
  font-stretch:normal;
}

* {
  font-family: neue-haas-grotesk-display;
  font-weight: 500;
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171b1c;
  color: #bc955c
}

.app{/* container for everything on page including navbar and footer */
  position: relative;
  min-height: 100vh;
}

.page-content {/* container for everything besides navbar and footer */
  z-index: 0;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

/* GENERAL PAGE CONTENT: */

.content-box{
  display: flex;
  flex-direction: column;
}

.content-box h1, h3, p, img{
  margin: auto;
}

.content-box h1, h3 {
  text-align: center;
}

.content-box p{
  margin-top: 20px;
  width: 50vw;
  display: inline-block;
  text-align: left;
}

.summary-box p{ /* Basically only to do centering on home page */
  text-align: center;
}

.content-box img {
  margin-top: 20px;
}

a {
  text-decoration: none;
}

.summary-box img{
  width: 45vh;
}

.text-link {
  text-decoration: underline;
  color: #bc955c;
}

.text-link:hover {
  color: #017dbb;
}

/* NAVBAR: */

.navbar, button {
  color: #171b1c;
  background-color: #017dbb;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: fit-content;
  border-radius: 8px;
  z-index: 2;
}

.navbar{
  margin-top: 10px;
  position: fixed;
}

.nav-link {
  padding: 10px;
  border-radius: 6px;
  color: #171b1c;
  text-decoration: none;
  transition: background-color 0.7s;
  z-index: 4;
  position: relative;
}

.hover-selector {
  top: 10px;
  bottom: 10px;
  background-color: #bc955c;
  border-radius: 6px;
  position: absolute;
  opacity: 0;
  z-index: 3;
}

.nav-link:hover ~ .hover-selector {
  opacity: 1;
}

/* RESUME DISPLAY: */

.resume-content {/* containter for resume image + download button */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.resume-content img {
  width: auto;
  height: 65vh;
}

/* BUTTONS: */

button {
  border: 0;
  margin: auto;
  margin-top: 20px;
  font-size: medium;
}

button:hover {
  color: #bc955c;
  cursor:pointer;
}

/* PROFILE PAGE: */

.profile-page {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.profile-box {
  width: 350px;
}

.profile-box h3{
  width: inherit;
  margin: auto;
  margin-top: 20px;
  color: #bc955c;
}

.profile-logo {
  fill: #bc955c;
}

.profile-box:hover > .profile-logo{
  fill: #017dbb;
}

.profile-box:hover h3{
  color: #017dbb;
}

/* NOTFOUND/404: */

.not-found-box img {
  width: 25vh;
}

/*.not-found-box h3 {
  text-align: left;
}*/

/* RECOMMENDER TAB: */

.recommender-tab {
  position: fixed;
  color: #171b1c;
  background-color: #017dbb;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  bottom: 50px;
  right: 0px;
  transform: translateX(100%) translateX(-40px);
  transition: transform 0.7s;
}

.recommender-tab .text-link{
  color: #171b1c;
}

.recommender-tab .text-link:hover {
  color: #bc955c;
}

.recommender-tab:hover {
  transform: translateX(10px);
}

.recommender-tab p {
  padding: 10px;
  margin-right: 10px;
}

.handle{
  height: auto;
  border-radius: 2px;
  width: 4px;
  left: 10px;
  margin-right: 20px;
  background-color: #bc955c;
}

/* FOOTER: */

.footer {
  text-align: center;
  margin-top: 20px;
  bottom: 10px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

/* MOBILE RESPONSIVENESS: */

@media (max-width: 1024px) {

  .summary-box img{
    width: 50vw;
  }

  .content-box p, h1, h3{
    width: 65vw;
  }

  .resume-content img {
    width: 85vw;
    height: auto;
  }

  .profile-box {
    width: 300px;
  }
  
}

@media (max-width: 768px) {
  .hover-selector {
    display: none;
  }

  .recommender-tab {
    display: none;
  }

  .summary-box img{
    width: 65vw;
  }

  .content-box p, h1, h3{
    width: 75vw;
  }

  .profile-box {
    width: 225px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .not-found-box img {
  width: 25vw;
  }

}

@media (max-width: 576px) {
  .summary-box img{
    width: 70vw;
  }

  .content-box p, h1, h3{
    width: 80vw;
  }

  .profile-box h3{
    display: none;
  }

}
